// 1. BACKEND BASE URL
// export const BACKEND_BASE_URL = "http://192.168.100.32:2000"
// export const BACKEND_BASE_HOST = "192.168.100.32:2000"
export const BACKEND_BASE_URL = "https://secretlab-backoffice.onrender.com"
export const BACKEND_BASE_HOST = "secretlab-backoffice.onrender.com"


// 2. AUTHENTICATION
export const SIGN_IN = "/api/hr/auth/login/"
export const SIGN_OUT = "/api/hr/auth/logout/"
export const VERIFY_EMAIL = "/api/hr/member/email/verify/"
export const CONFIRM_CODE = "/api/hr/member/code/confirm/"
export const CHANGE_PASSWORD = "/api/hr/member/password/change/"


// 3. NOTIFICATION
export const MY_NOTIFICATIONS = "/api/core/notification/my/"
export const MY_ALL_NOTIFICATIONS = "/api/core/notification/list/"
export const SET_NOTIFICATION_SEEN = "/api/core/notification/seen/"
export const PROJECT_MENU_COUNTER = "/api/project/task/menu/counter/"


// 4. ADMINISTRATION
export const EVENT_CATEGORY = "/api/core/settings/event/category/"
export const STATUS = "/api/core/settings/status/"
export const ACCOUNT = "/api/core/access/account/"
export const ACTIVITY = "/api/core/access/activity/"
export const ACTIVITY_RESET = "/api/core/access/activity/reset/"


// 5. SECRET
export const PHONE_NUMBER = "/api/secret/phone/number/"
export const SALARY = "/api/secret/salary/"
export const PLATE = "/api/secret/plate/"


// 6. TRANSACTION
export const EXPENSE_CATEGORY = "/api/core/settings/expense/category/"
export const INCOME_CATEGORY = "/api/core/settings/income/category/"
export const BALANCE = "/api/wallet/balance/"
export const TRANSACTION = "/api/wallet/transaction/data/"
export const MY_TRANSACTION = "/api/wallet/transaction/mine/"
export const MY_BALANCE_HISTORY = "/api/wallet/balance/history/mine/"
export const EXPENSE_TRANSACTION = "/api/wallet/transaction/expense/"
export const INCOME_TRANSACTION = "/api/wallet/transaction/income/"
export const TRANSACTION_DETAILS = "/api/wallet/transaction/details/"


// 6. MEMBER
export const MEMBER = "/api/hr/user/member/"
export const MEMBER_SIMPLE = "/api/hr/user/minimal/member/"
export const MEMBER_ROLE = "/api/hr/user/member/role/"
export const MEMBER_ROLE_CHANGE_ORDER = "/api/hr/user/role/order/change/"
export const MEMBER_ROLE_SIMPLE = "/api/hr/user/member/role/"
export const MEMBER_DETAIL = "/api/hr/user/member/"
export const RESET_MEMBER_PASSWORD = "/api/hr/auth/member/password/reset/"


// 8. STATISTICS
export const APPLICATION_STATISTICS = "/api/report/application/stats/application/"
export const DASHBOARD_STATISTICS = "/api/report/dashboard/stats/counter/admin/"
export const BALANCE_STATISTICS = "/api/report/dashboard/balance/stats/counter/admin/"
export const REVIEW = "/api/report/review/list/"


// 9. PERMISSIONS
export const PERMISSION = "/api/core/access/permission/"
export const PERMISSIONS_TREE_LIST = "/api/core/access/permissions/tree/"
export const ADD_PERMISSION = "/api/core/access/permission/"
export const PERMISSIONS_TREE = "/api/core/access/permissions/tree/list/"
export const ROLE_PERMISSIONS_UPDATE = "/api/core/access/permissions/role/update/"
export const ROLE_PERMISSIONS_TREE = "/api/core/access/permissions/role/tree/"
export const MY_PERMISSIONS_TREE = "/api/core/access/my/permissions/"
export const MY_PERMISSIONS = "/api/core/access/my/permissions/"


// 10. INVOICE
export const INVOICE_CONTACT = "/api/invoice/contact/unity/"
export const INVOICE = "/api/invoice/bill/receipt/"
export const INVOICE_MINE = "/api/invoice/bill/mine/receipt/"


// 11. PROJECT
export const EVENT = "/api/project/agenda/event/"
export const TASK_BOARD = "/api/project/task/board/"
export const TASK_BOARD_MINE = "/api/project/task/mine/"
export const TASK_MINE = "/api/project/task/assignment/mine/"
export const TASK_CREATED = "/api/project/task/assignment/created/"
export const TASK_RECEIVED = "/api/project/task/assignment/received/"


// 12. THIRD PARTY
export const COUNTRIES = "https://countriesnow.space/api/v0.1/countries/positions"
export const CITIES = "https://countriesnow.space/api/v0.1/countries/cities/q?country="
export const CURRENCIES = "https://countriesnow.space/api/v0.1/countries/currency"
