import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    newNotificationCounter: 0,
	latestNotifications: [],
};


export const notificationSlice = createSlice({
	name: 'Notification',
	initialState,
	reducers: {
		setNotificationsData: (state, action) => {
			state.newNotificationCounter = action.payload.count;
            state.latestNotifications = action.payload.items;
		},
		setNewNotificationsCounter: (state, action) => {
			state.newNotificationCounter = action.payload;
		},
        setLatestNotifications: (state, action) => {
			state.latestNotifications = action.payload;
		},
		resetNotifications: (state) => {
			return initialState;
		}
	},
});


export const {
	setNotificationsData,
	setNewNotificationsCounter,
    setLatestNotifications,
	resetNotifications,
} = notificationSlice.actions;


export default notificationSlice.reducer;