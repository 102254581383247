import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/ThemeSlice';
import { useDispatch } from 'react-redux';


const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout, dispatch])
	
	return (
		<Component {...props} />
	)
}

export default AppRoute