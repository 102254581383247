import dayjs from "dayjs";
import { lowerCase } from "lodash";


export const getFormattedNumber = (givenNumber) => {
  if (isValidValue(givenNumber) === false) {
    return null;
  }
  return parseFloat(givenNumber).toFixed(2)?.toLocaleString();
};


export const setFormFieldValue = (givenValue) => {
    if (isValidValue(givenValue) === false) {
        return null;
    }
    return givenValue;
};


export const formatLocalNumber = (value) => {
    if (isValidValue(value) === false) {
        return null;
    }

    return value?.toLocaleString();
};


export const setDateFieldValue = (givenDate, dateFormat) => {
    if (isValidValue(givenDate) === false) {
        return null;
    }

    const formattedDate = dayjs(givenDate, dateFormat);
    return formattedDate;
};


export const setDateValue = (givenDate, dateFormat) => {
    if (isValidValue(givenDate) === false) {
        return null;
    }

    const formattedDate = dayjs(givenDate).format(dateFormat);
    return formattedDate;
};


export const formatIntegerId = (givenId) => {
    if (isValidValue(givenId) === false) {
        return null;
    }

    return parseInt(givenId);
};


export const isValidValue = (value) => {
    if (value === null || value === undefined || value === "" || lowerCase(value) === "null" || lowerCase(value) === "undefined" || value?.toString()?.includes(lowerCase("null "))) {
        return false;
    }
    return true;
};


export const setDisabledDates = (currentDate, givenDate) => {
    const formattedDate = dayjs(givenDate).format("YYYY-MM-DD");
    return currentDate && currentDate < dayjs(formattedDate, "YYYY-MM-DD");
};


export const setDate = (date) => {
    if (date) {
        return dayjs(date, "YYYY-MM-DD");
    }

    return null;
};


export const setUrlFile = (file) => {
    if (isValidValue(file) === false) {
        return null;
    }

    return file.replace('https//', 'https://');
};


export const splitPhonePrefix = (value) => {
    if (isValidValue(value) === false) {
        return null;
    }

    if (value?.toString()?.includes("+355")) {
        return value?.toString()?.split("+355")[1];
    }

    return value;
};
