import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
	project: {
		'project-task-received': 4,
		'project-task-mine': 4,
		'project-task-created': 1,
	},
};


export const menuCounterSlice = createSlice({
	name: 'MenuCounter',
	initialState,
	reducers: {
		setProjectMenuCounters: (state, action) => {
			state.project = action.payload;
		},
		setProjectMenuCounter: (state, action) => {
			const menuKey = action.payload.key;
			const counter = action.payload.counter;
			state.project[menuKey] = counter;
		},
		resetMenuCounter: (state) => {
			return initialState;
		}
	},
});


export const {
	setProjectMenuCounters,
	setProjectMenuCounter,
	resetMenuCounter,
} = menuCounterSlice.actions;


export default menuCounterSlice.reducer;