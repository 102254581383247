import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'services/AuthService';
import { setMemberInfo } from './MemberSlice';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { setPermissions } from './PermissionSlice';
import { setUrlFile } from 'helpers/FormHelper';


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: null
};


export const signIn = createAsyncThunk('auth/signIn', async (data, { dispatch, rejectWithValue }) => {
	try {
		const languageCode = data.languageCode;
		const apiResponse = (await AuthService.signIn(data, languageCode)).data;
		if (apiResponse.success === true) {
			const accessToken = apiResponse.result.accessToken;
			const memberInfo = apiResponse.result.member;
			const permissions = apiResponse.result.permissions;
			dispatch(authenticated(accessToken));
			dispatch(setMemberInfo({ ...memberInfo, photo: setUrlFile(memberInfo?.photo) }));
			dispatch(setPermissions(permissions));
			return accessToken;
		} else {
			const errorMessage = apiResponse.message;
			return rejectWithValue(errorMessage);
		}
	} catch (err) {
		return rejectWithValue('Server error!');
	}
});


export const signOut = createAsyncThunk('Auth/signOut', async () => {
	const apiResponse = (await AuthService.signOut(localStorage.getItem(AUTH_TOKEN))).data;
	console.log("API RESPONSE: ", apiResponse);
	return null;
});


export const authSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/';
			state.token = action.payload;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.loading = false;
		},
		hideAuthMessage: (state) => {
			state.message = '';
			state.showMessage = false;
		},
		signOutSuccess: (state) => {
			state.loading = false;
			state.token = null;
			state.redirect = '/';
		},
		showLoading: (state) => {
			state.loading = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
	},
});


export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions;


export default authSlice.reducer;