import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    memberInfo: null,
	roles: null,
	activeRole: null,
    refreshIndex: 0
};


export const memberSlice = createSlice({
	name: 'Member',
	initialState,
	reducers: {
        setMemberInfo: (state, action) => {
			state.memberInfo = action.payload;
		},
		setMemberRoles: (state, action) => {
			state.roles = action.payload;
		},
		setActiveRole: (state, action) => {
			state.activeRole = action.payload;
		},
        resetMemberInfo: (state, action) => {
			state.memberInfo = null;
		},
        setRefreshIndex: (state, action) => {
			state.refreshIndex = state.refreshIndex + 1;
		},
        updateProfile: (state, action) => {
			state.memberInfo.avatar = action.payload.avatar;
            state.refreshIndex = state.refreshIndex + 1;
		},
	},
});


export const { 
    setMemberInfo,
	setMemberRoles,
	setActiveRole,
    resetMemberInfo,
    setRefreshIndex,
    updateProfile
} = memberSlice.actions;


export default memberSlice.reducer;