export const DASHBOARD_KEY = 'DASHBOARD';
export const DASHBOARD_MAIN_KEY = 'DASHBOARD_MAIN';

export const PROJECT_KEY = 'PROJECT';
export const PROJECT_AGENDA_KEY = 'PROJECT_EVENT';
export const PROJECT_TASK_MINE_KEY = 'PROJECT_TASK_MINE';
export const PROJECT_TASK_CREATED_KEY = 'PROJECT_TASK_CREATED';
export const PROJECT_TASK_RECEIVED_KEY = 'PROJECT_TASK_RECEIVED';

export const INVOICE_KEY = 'INVOICE';
export const INVOICE_REGISTER_KEY = 'INVOICE_REGISTER';
export const INVOICE_MINE_KEY = 'INVOICE_MINE';
export const INVOICE_ALL_KEY = 'INVOICE_ALL';

export const BALANCE_KEY = 'BALANCE';
export const BALANCE_USER_KEY = 'BALANCE_USER';
export const BALANCE_TRANSACTION_KEY = 'BALANCE_TRANSACTION_HISTORY';
export const BALANCE_TRANSACTION_MINE_KEY = 'BALANCE_TRANSACTION_MINE';
export const BALANCE_MINE_KEY = 'BALANCE_MINE';
export const BALANCE_ACCOUNT_KEY = 'BALANCE_ACCOUNT_MINE';

export const SECRET_KEY = 'SECRET';
export const SECRET_PHONE_NUMBER_KEY = 'SECRET_PHONE_NUMBER';
export const SECRET_SALARY_KEY = 'SECRET_SALARY';
export const SECRET_PLATE_KEY = 'SECRET_PLATE';

export const ADMINISTRATION_KEY = 'ADMINISTRATION';
export const ADMINISTRATION_USER_KEY = 'ADMINISTRATION_USER';
export const ADMINISTRATION_USER_MEMBER_KEY = 'ADMINISTRATION_USER_MEMBER';
export const ADMINISTRATION_USER_ROLE_KEY = 'ADMINISTRATION_USER_ROLE';
export const ADMINISTRATION_USER_ROLE_CHANGE_ORDER_KEY = 'ADMINISTRATION_USER_ROLE_CHANGE_ORDER';
export const ADMINISTRATION_USER_PERMISSION_KEY = 'ADMINISTRATION_USER_PERMISSION';
export const ADMINISTRATION_USER_PERMISSION_ROLE_KEY = 'ADMINISTRATION_USER_ROLE_PERMISSION';

export const ADMINISTRATION_SETTINGS_KEY = 'ADMINISTRATION_SETTINGS';
export const ADMINISTRATION_SETTINGS_STATUS_KEY = 'ADMINISTRATION_SETTINGS_STATUS';
export const ADMINISTRATION_SETTINGS_EXPENSE_CATEGORY_KEY = 'ADMINISTRATION_SETTINGS_EXPENSE_CATEGORY';
export const ADMINISTRATION_SETTINGS_INCOME_CATEGORY_KEY = 'ADMINISTRATION_SETTINGS_INCOME_CATEGORY';
export const ADMINISTRATION_SETTINGS_EVENT_CATEGORY_KEY = 'ADMINISTRATION_SETTINGS_EVENT_CATEGORY';

export const ADMINISTRATION_ACTIVITY_LOG_KEY = 'ADMINISTRATION_ACTIVITY_LOG';
