import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';
import { ADMINISTRATION_ACTIVITY_LOG_KEY, ADMINISTRATION_SETTINGS_EVENT_CATEGORY_KEY, ADMINISTRATION_SETTINGS_EXPENSE_CATEGORY_KEY, ADMINISTRATION_SETTINGS_INCOME_CATEGORY_KEY, ADMINISTRATION_SETTINGS_STATUS_KEY, ADMINISTRATION_USER_MEMBER_KEY, ADMINISTRATION_USER_PERMISSION_KEY, ADMINISTRATION_USER_PERMISSION_ROLE_KEY, ADMINISTRATION_USER_ROLE_KEY, BALANCE_ACCOUNT_KEY, BALANCE_MINE_KEY, BALANCE_TRANSACTION_KEY, BALANCE_TRANSACTION_MINE_KEY, BALANCE_USER_KEY, DASHBOARD_MAIN_KEY, INVOICE_ALL_KEY, INVOICE_MINE_KEY, INVOICE_REGISTER_KEY, PROJECT_TASK_CREATED_KEY, PROJECT_TASK_MINE_KEY, PROJECT_TASK_RECEIVED_KEY, SECRET_PHONE_NUMBER_KEY, SECRET_PLATE_KEY, SECRET_SALARY_KEY } from 'constants/PermissionConstant';


export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'verify',
        path: `${AUTH_PREFIX_PATH}/email/verify`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-email')),
    },
    {
        key: 'code',
        path: `${AUTH_PREFIX_PATH}/code/confirm`,
        component: React.lazy(() => import('views/auth-views/authentication/set-code')),
    },
];



export const protectedRoutes = [
    {
        key: 'dashboard.default',
        hasPermission: true,
        permissionKey: DASHBOARD_MAIN_KEY,
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/main')),
    },
    {
        key: 'invoice.create',
        hasPermission: true,
        permissionKey: INVOICE_REGISTER_KEY,
        path: `${APP_PREFIX_PATH}/invoice/create`,
        component: React.lazy(() => import('views/app-views/invoice/create-invoice')),
    },
    {
        key: 'invoice.mine',
        hasPermission: true,
        permissionKey: INVOICE_MINE_KEY,
        path: `${APP_PREFIX_PATH}/invoice/mine`,
        component: React.lazy(() => import('views/app-views/invoice/my-invoices')),
    },
    {
        key: 'invoice.member',
        hasPermission: true,
        permissionKey: INVOICE_ALL_KEY,
        path: `${APP_PREFIX_PATH}/invoice/member`,
        component: React.lazy(() => import('views/app-views/invoice/member-invoices')),
    },
    {
        key: 'balance.member',
        hasPermission: true,
        permissionKey: BALANCE_USER_KEY,
        path: `${APP_PREFIX_PATH}/balance/member`,
        component: React.lazy(() => import('views/app-views/balance/member-balances')),
    },
    {
        key: 'balance.account',
        hasPermission: true,
        permissionKey: BALANCE_ACCOUNT_KEY,
        path: `${APP_PREFIX_PATH}/balance/account`,
        component: React.lazy(() => import('views/app-views/balance/my-accounts')),
    },
    {
        key: 'transaction.member',
        hasPermission: true,
        permissionKey: BALANCE_TRANSACTION_KEY,
        path: `${APP_PREFIX_PATH}/balance/transaction`,
        component: React.lazy(() => import('views/app-views/balance/member-transactions')),
    },
    {
        key: 'transaction.member.mine',
        hasPermission: true,
        permissionKey: BALANCE_TRANSACTION_MINE_KEY,
        path: `${APP_PREFIX_PATH}/balance/transaction/mine`,
        component: React.lazy(() => import('views/app-views/balance/my-transactions')),
    },
    {
        key: 'balance.history.mine',
        hasPermission: true,
        permissionKey: BALANCE_MINE_KEY,
        path: `${APP_PREFIX_PATH}/balance/history/mine`,
        component: React.lazy(() => import('views/app-views/balance/my-balance-history')),
    },
    {
        key: 'secret.phone.number',
        hasPermission: true,
        permissionKey: SECRET_PHONE_NUMBER_KEY,
        path: `${APP_PREFIX_PATH}/secret/phone/number`,
        component: React.lazy(() => import('views/app-views/secret/phone-numbers')),
    },
    {
        key: 'secret.salary',
        hasPermission: true,
        permissionKey: SECRET_SALARY_KEY,
        path: `${APP_PREFIX_PATH}/secret/salary`,
        component: React.lazy(() => import('views/app-views/secret/salaries')),
    },
    {
        key: 'secret.plate',
        hasPermission: true,
        permissionKey: SECRET_PLATE_KEY,
        path: `${APP_PREFIX_PATH}/secret/plate`,
        component: React.lazy(() => import('views/app-views/secret/license-plates')),
    },
    {
        key: 'administration.settings.status',
        hasPermission: true,
        permissionKey: ADMINISTRATION_SETTINGS_STATUS_KEY,
        path: `${APP_PREFIX_PATH}/administration/settings/status`,
        component: React.lazy(() => import('views/app-views/administration/settings/statuses')),
    },
    {
        key: 'administration.settings.event.category',
        hasPermission: true,
        permissionKey: ADMINISTRATION_SETTINGS_EVENT_CATEGORY_KEY,
        path: `${APP_PREFIX_PATH}/administration/settings/event/category`,
        component: React.lazy(() => import('views/app-views/administration/settings/event-categories')),
    },
    {
        key: 'administration.settings.expense.category',
        hasPermission: true,
        permissionKey: ADMINISTRATION_SETTINGS_EXPENSE_CATEGORY_KEY,
        path: `${APP_PREFIX_PATH}/administration/settings/expense/category`,
        component: React.lazy(() => import('views/app-views/administration/settings/expense-categories')),
    },
    {
        key: 'administration.settings.income.category',
        hasPermission: true,
        permissionKey: ADMINISTRATION_SETTINGS_INCOME_CATEGORY_KEY,
        path: `${APP_PREFIX_PATH}/administration/settings/income/category`,
        component: React.lazy(() => import('views/app-views/administration/settings/income-categories')),
    },
    {
        key: 'administration.user.role',
        hasPermission: true,
        permissionKey: ADMINISTRATION_USER_ROLE_KEY,
        path: `${APP_PREFIX_PATH}/administration/user/role`,
        component: React.lazy(() => import('views/app-views/administration/users/users-roles')),
    },
    {
        key: 'administration.user.permission',
        hasPermission: true,
        permissionKey: ADMINISTRATION_USER_PERMISSION_KEY,
        path: `${APP_PREFIX_PATH}/administration/user/permission`,
        component: React.lazy(() => import('views/app-views/administration/users/user-permissions')),
    },
    {
        key: 'administration.user.permission.role',
        hasPermission: true,
        permissionKey: ADMINISTRATION_USER_PERMISSION_ROLE_KEY,
        path: `${APP_PREFIX_PATH}/administration/user/permission/role`,
        component: React.lazy(() => import('views/app-views/administration/users/role-permissions')),
    },
    {
        key: 'administration.user.member',
        hasPermission: true,
        permissionKey: ADMINISTRATION_USER_MEMBER_KEY,
        path: `${APP_PREFIX_PATH}/administration/user/member`,
        component: React.lazy(() => import('views/app-views/administration/users/users-list')),
    },
    {
        key: 'administration.activity',
        hasPermission: true,
        permissionKey: ADMINISTRATION_ACTIVITY_LOG_KEY,
        path: `${APP_PREFIX_PATH}/administration/activity`,
        component: React.lazy(() => import('views/app-views/administration/activity')),
    },
    {
        key: 'project.agenda',
        hasPermission: true,
        permissionKey: ADMINISTRATION_ACTIVITY_LOG_KEY,
        path: `${APP_PREFIX_PATH}/project/event`,
        component: React.lazy(() => import('views/app-views/project/agenda')),
    },
    {
        key: 'project.task.mine',
        hasPermission: true,
        permissionKey: PROJECT_TASK_MINE_KEY,
        path: `${APP_PREFIX_PATH}/project/task/mine`,
        component: React.lazy(() => import('views/app-views/project/scrumboard')),
    },
    {
        key: 'project.task.created',
        hasPermission: true,
        permissionKey: PROJECT_TASK_CREATED_KEY,
        path: `${APP_PREFIX_PATH}/project/task/created`,
        component: React.lazy(() => import('views/app-views/project/created-tasks')),
    },
    {
        key: 'project.task.received',
        hasPermission: true,
        permissionKey: PROJECT_TASK_RECEIVED_KEY,
        path: `${APP_PREFIX_PATH}/project/task/received`,
        component: React.lazy(() => import('views/app-views/project/received-tasks')),
    },
    {
        key: 'my.profile',
        hasPermission: false,
        permissionKey: null,
        path: `${APP_PREFIX_PATH}/profile/info`,
        component: React.lazy(() => import('views/app-views/profile/my-profile')),
    },
    {
        key: 'my.password',
        hasPermission: false,
        permissionKey: null,
        path: `${APP_PREFIX_PATH}/profile/password`,
        component: React.lazy(() => import('views/app-views/profile/my-password')),
    },
    {
        key: 'my.notification',
        hasPermission: false,
        permissionKey: null,
        path: `${APP_PREFIX_PATH}/notification`,
        component: React.lazy(() => import('views/app-views/profile/my-notifications')),
    },
];