import antdEnUS from 'antd/es/locale/en_US';
import antdDeDE from 'antd/es/locale/de_DE';
import antdFrFR from 'antd/es/locale/fr_FR';
import antdItIT from 'antd/es/locale/it_IT';
import de from './locales/de_DE.json'
import al from './locales/al_AL.json'
import en from './locales/en_US.json'
import fr from './locales/fr_FR.json'
import it from './locales/it_IT.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { THEME_CONFIG } from 'configs/AppConfig';


export const resources = {
    al: {
        translation: al,
        antd: antdEnUS
    },
    de: {
        translation: de,
        antd: antdDeDE
    },
    en: {
        translation: en,
        antd: antdEnUS
    },
    fr: {
        translation: fr,
        antd: antdFrFR
    },
    it: {
        translation: it,
        antd: antdItIT
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: THEME_CONFIG.locale,
    interpolation: {
        escapeValue: false 
    }
})

export default i18n;